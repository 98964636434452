<template>
  <div class="insolvency-factor">
    <b-table
      class="text-center "
      :fields="[
        { key: 'key', label: ''},
        { key: 'value', label: ''}
      ]"
      :items="lastItem"
    >

      <template #cell(key)="data">
        <div v-if="lastItem.length === data.index+1">
          <h6>
            {{ data.item.key }}
          </h6>
        </div>

        <div v-else>
          {{ data.item.key }}
        </div>
      </template>
      <template #cell(value)="data">
        <div>
          <div
            v-if="data.item.isGrade"
            :class="`bg-${gradeColor[data.item.value].background} p-1`"
          >
            <span
              style="font-weight: bold;"
              :class="`text-${gradeColor[data.item.value].text}`"
            >
              {{ data.item.value1 + '   ' + gradeColor[data.item.value].message }}
            </span>
          </div>

          <div
            v-else
            class="p-1"
          >
            {{ data.item.value }}
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue';

import { gradeColor } from '../../grade-color';

const translated = {
    return_on_equity: 'Rentabilidade do Patrimônio Liquido',
    general_liquidity: 'Indíce de Liquidez Geral',
    dry_liquidity: 'Indíce de Liquidez Seca',
    liquidity_current: 'Indíce de Liquidez Corrente',
    degree_indebtedness: 'Grau de Endividamento',
};

export default {
    components: {
        BTable,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            gradeColor,
        };
    },
    computed: {
        lastItem() {
            const data = this.data.index.map(item => item.insolvency_factor);

            const lastItem = data[data.length - 1];

            if (!lastItem) return [];

            const items = Object.entries(lastItem).filter(([key]) => translated[key]).map(([key, value]) => ({
                key: translated[key],
                value,
            }));

            items.push({
                key: 'Fator de Insolvência', value: lastItem.grade_id, value1: lastItem.total, isGrade: true,
            });

            return items;
        },
    },
};
</script>

<style>
.insolvency-factor table tbody tr td {
    padding: 0 !important;
}
</style>
