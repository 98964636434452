<template>
  <b-container fluid>
    <b-card>
      <balance-form
        :company-id="companyId"
        :file-id="fileId"
        @button-clicked="(data) =>
          $emit('ready', data)"
      />
    </b-card>
    <b-card v-if="balanceData">
      <ViewData
        :data="balanceData"
        @delete="deleteBalance"
      />
    </b-card>
  </b-container>
</template>

<script>
import {
    BContainer, BCard,
} from 'bootstrap-vue';
import BalanceForm from './Form.vue';

import BalanceService from '@/service/balance/';

import ViewData from './ViewData.vue';

const DEFAULT = { company: { id: 0 }, file: { id: 0 } };

export default {
    components: {
        BalanceForm, BContainer, BCard, ViewData,
    },

    props: {
        balanceData: {
            type: Object,
            default: () => (DEFAULT),
        },
    },

    computed: {
        companyId() {
            return this.balanceData?.company?.id || 0;
        },

        fileId() {
            return this.balanceData?.file?.id || 0;
        },
    },

    methods: {
        async deleteBalance() {
            const isConfirmed = await this.confirmAnAction('Você deseja realmente deletar esse balanco?');

            if (!isConfirmed) return;

            await BalanceService.revoke(this.fileId);

            window.location.reload();
        },
    },
};
</script>
