<template>
  <div class="mt-4">
    <h4> {{ title }} </h4>
    <b-table
      class="text-center"
      :fields="fields"
      :items="items"
      show-empty
      empty="Não possui"
    >
      <template #cell(date)="data">
        {{ data.item.date | date }}
      </template>

      <template #cell(value)="data">
        <span v-if="data.item.currency === 'R$'">
          {{ data.item.value | value }}
        </span>

        <span v-else>
          {{ `${data.item.currency} ${String(data.item.value).replace('.', ',')}` }}
        </span>
      </template>

      <template #empty>
        Consulta Não Possui {{ title }}
      </template>
    </b-table>

    <div class="d-flex flex-column">
      <span> Quantidade: {{ descValues.quantity }}</span>
      <span> Total: {{ descValues.value | value }}</span>
    </div>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue';

export default {

    components: {
        BTable,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        fields: {
            type: Array,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },

        descValues: {
            type: Object,
            default: () => ({
                value: 0,
                quantity: 0,
            }),
        },
    },
};
</script>

<style>
th {
  width: 25%;
  box-sizing: border-box;
}
</style>
