<template>
  <div>
    <b-row class="mb-2 justify-content-center position-relative">
      <b-col md="2">
        <card-header title="Razão Social">
          {{ data.company.legal_tax_name }}
        </card-header>
      </b-col>

      <b-col md="2">
        <card-header title="CNPJ">
          {{ data.company.tax_id | tax_id }}
        </card-header>
      </b-col>

      <b-col md="2">
        <card-header title="Data da Fundação">
          {{ data.company.foundation_date | date }}
        </card-header>
      </b-col>

      <b-col md="3">
        <card-header title="Limite de Crédito Recomendado">
          <span
            v-if="data.score.grade === 'E'"
            class="text-danger"
          >
            {{ data.score.message }}
          </span>

          <span v-else>
            {{ data.score.recommended | value }}
          </span>
        </card-header>
      </b-col>

      <b-button
        variant="danger"
        style="right: 0"
        class="position-absolute"
        @click="$emit('delete')"
      >
        Apagar
      </b-button>

    </b-row>

    <b-tabs
      vertical
      pills
      nav-class="nav-left"
    >
      <b-tab title="Balanço">
        <balance :balance="data" />
      </b-tab>

      <b-tab title="Relatório">
        <Report :data="data" />
      </b-tab>

      <b-tab title="SCR">
        <scr :data="data" />
      </b-tab>

      <b-tab title="Índice">
        <Index :data="data" />
      </b-tab>

      <b-tab title="Serasa">
        <Serasa :data="data" />
      </b-tab>

      <!-- <b-tab title="Processos">
        <TaxIdAnalye :data="data" />
      </b-tab> -->

      <b-tab title="Parecer">
        <CreditOpinion :data="data" />
      </b-tab>

      <b-tab
        v-if="data.group.length"
        title="Grupo"
      >
        <Group :data="data" />
      </b-tab>

      <b-tab
        title="Resumo"

        @click="$router.push(`/balanco/visualizar/resumo/${data.id}`)"
      />

      <b-tab
        v-if="data.reanalyze && data.balance_type_id === 2"
        title="Reanalise"
        @click="$router.push(`/balanco/visualizar/reanalise/${data.id}`)"
      />
    </b-tabs>
  </div>
</template>

<script>
import {
    BTab, BTabs, BRow, BCol, BButton,
} from 'bootstrap-vue';

import CardHeader from './Card/CardHeader.vue';

import Report from './Report/Report.vue';
import Serasa from './Serasa/Serasa.vue';
import Scr from './SCR/SCR.vue';
import Balance from './BalanceTypes/Root.vue';
import Index from './Index/Index.vue';
import CreditOpinion from './CreditOpinion/CreditOpinion.vue';
// import TaxIdAnalyze from './TaxIdAnalyse/TaxIdAnalyse.vue';
import Group from './Group/Group.vue';

export default {
    components: {
        // TaxIdAnalyze,
        BTab,
        BTabs,
        Report,
        BRow,
        Serasa,
        BCol,
        Scr,
        Group,
        CardHeader,
        Index,
        Balance,
        BButton,
        CreditOpinion,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
    },
};
</script>
