var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"mx-auto",attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Sócio com Restrição","label-for":"partner-with-restriction"}},[_c('b-form-select',{attrs:{"id":"partner-with-restriction","options":_vm.partners},model:{value:(_vm.partner),callback:function ($$v) {_vm.partner=$$v},expression:"partner"}})],1)],1)],1)],1),(_vm.partner)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('SerasaSummary',{attrs:{"pefin":_vm.pefin,"bad-check":!!_vm.partner.badCheck.length,"refin":_vm.refin,"protest":_vm.protest,"law-suit":_vm.lawSuit,"has-injuction":_vm.data.hasInjuction,"expired-debts":_vm.expiredDebts}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('serasa-table',{attrs:{"title":"Pefin","fields":[
          { key: 'date', label: 'Data' },
          { key: 'value', label: 'Valor' },
          { key: 'contract', label: 'Contrato' },
          { key: 'origin', label: 'Origem'}
        ],"items":_vm.partner.pefin,"desc-values":_vm.pefin}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('serasa-table',{attrs:{"title":"Refin","fields":[
          { key: 'date', label: 'Data' },
          { key: 'value', label: 'Valor' },
          { key: 'contract', label: 'Contrato' },
          { key: 'origin', label: 'Origem'}
        ],"items":_vm.partner.refin,"desc-values":_vm.refin}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('serasa-table',{attrs:{"title":"Protesto","fields":[
          { key: 'date', label: 'Data' },
          { key: 'value', label: 'Valor' },
          { key: 'state', label: 'Estado'},
          { key: 'city', label: 'Cidade' } ],"items":_vm.partner.protest,"desc-values":_vm.protest}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('serasa-table',{attrs:{"title":"Ação Judicial","fields":[
          { key: 'date', label: 'Data' },
          { key: 'value', label: 'Valor' },
          { key: 'nature', label: 'natureza' },
          { key: 'city', label: 'Cidade'}
        ],"items":_vm.partner.lawSuit,"desc-values":_vm.lawSuit}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('serasa-table',{attrs:{"title":"Dívida Vencida","fields":[
          { key: 'date', label: 'Data' },
          { key: 'value', label: 'Valor' },
          { key: 'name', label: 'nome' },
          { key: 'city', label: 'Cidade'}
        ],"items":_vm.partner.expiredDebts,"desc-values":_vm.expiredDebts}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('serasa-table',{attrs:{"title":"Cheque Sem Fundo","fields":[
          { key: 'date', label: 'Data' },
          { key: 'value', label: 'Valor' },
          { key: 'check', label: 'Número' },
          { key: 'city', label: 'Cidade'}
        ],"items":_vm.partner.badCheck,"desc-values":_vm.badCheck}})],1),_c('b-col',{staticClass:"mt-4",attrs:{"md":"12"}},[_c('h4',[_vm._v(" Participação Societária ")]),_c('b-table',{staticClass:"text-center",attrs:{"fields":[
          { key: 'legalTaxName', label: 'Razão Social'},
          { key: 'taxId', label: 'CNPJ' },
          { key: 'participationPercentage', label: 'Porcentagem de Participação'},
          { key: 'state', label: 'Estado' },
          { key: 'participationInitDate', label: 'Desde'},
          { key: 'lastUpdate', label: 'Atualização'}
        ],"items":_vm.partner.companyParticipation},scopedSlots:_vm._u([{key:"cell(participationInitDate)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatDate(data.item.participationInitDate ))+" ")]}},{key:"cell(lastUpdate)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatDate(data.item.lastUpdate ))+" ")]}},{key:"cell(participationPercentage)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.participationPercentage / 10 + '%')+" ")]}}],null,false,2179675694)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }