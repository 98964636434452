export default {
    grossIncome: [
        { key: 'gross_sales_revenue', label: 'Receita Bruta de Vendas', bold: true },
        { key: 'returns_and_discounts', label: '(-) Descontos / Devoluções' },
        { key: 'taxes', label: '(-) Impostos' },
    ],

    netSalesRevenue: [
        { key: 'net_sales_revenue', label: ' = RECEITA LIQUIDA VENDAS', bold: true },
        { key: 'cost_of_goods_and_services_sold', label: '(-) Custo Produtos e Serviços' },
    ],

    grossProfit: [
        { key: 'gross_profit', label: ' = LUCRO (SOBRA) BRUTO', bold: true },
        { key: 'sales_marketing_distribution_expenses', label: '(-) Despesas com Vendas/Marketing/Distribuição' },
        { key: 'general_administrative_expenses', label: '(-) Despesas Gerais/Administrativas' },
        { key: 'equity', label: 'Equivalência Patrimonial' },
        { key: 'other_operational_expenses', label: '(-)Outras Despesas Operacionais' },
        { key: 'other_operational_revenues', label: '(+)Outras Receitas Operacionais' },
    ],

    profitAfterDepreciationEbit: [
        { key: 'profit_after_depreciation_ebit', label: '= LUCRO(SOBRA) PÓS DEPRECIAÇÃO -Ebit', bold: true },
        { key: 'positive_financial_revenues', label: '(+) Receitas Financeiras' },
        { key: 'negative_financial_expenses', label: '(-) Despesas Financeiras' },
        { key: 'non_operating_revenues', label: '(+) Receitas Não Operacionais ' },
        { key: 'negative_non_operating_expenses', label: '(-) Despesas Não Operacionais' },
        { key: 'monetary_and_foreign_exchange_variations', label: 'Variação Cambial' },
    ],

    profitBeforeIncomeTax: [
        { key: 'profit_before_income_tax', label: '= LUCRO(SOBRA) ANTES IR', bold: true },
        { key: 'current_social_contribution_and_income_tax', label: 'Imposto de renda e contribuição social - Corrente' },
        { key: 'social_contribution_and_income_tax_deferred', label: 'Imposto de renda e contribuição social - Deferido' },
    ],

    profitBeforeParticipation: [
        { key: 'profit_before_participation', label: '= LUCRO/SOBRA ANTES PARTICIPAÇÃO', bold: true },
        { key: 'equity_share', label: '(-) Participações' },
    ],

    netProfit: [
        { key: 'net_profit', label: ' = LUCRO(SOBRA) LIQUIDO EXERCÍCIO' },
    ],
};
