var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-header',[_c('SerasaSummary',{attrs:{"data":_vm.data}}),_c('b-row',{staticClass:"mt-4"},[_c('b-col',{attrs:{"md":"3"}},[_c('h4',[_vm._v(" Consultas ")]),_c('b-table',{staticClass:"text-center",attrs:{"items":_vm.data.consults,"fields":[
          { key: 'date', label: 'DATA'},
          { key: 'companyAmount', label: 'Quantidade'}
        ]},scopedSlots:_vm._u([{key:"cell(date)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.month)+" / "+_vm._s(data.item.year)+" ")]}}])})],1),_c('b-col',{attrs:{"md":"9"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h4',[_vm._v(" Últimas Consultas ")]),_c('b-table',{staticClass:"text-center",attrs:{"items":_vm.data.lastConsult,"fields":[
              { key: 'date', label: 'DATA'},
              { key: 'customerName', label: 'Empresa'},
              { key: 'amount', label: 'Quantidade'}
            ]},scopedSlots:_vm._u([{key:"cell(date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("date")(data.item.date))+" ")]}}])})],1),_c('b-col',{staticClass:"mt-4",attrs:{"md":"12"}},[_c('Relationship',{attrs:{"title":"Relacionamento com Mercado","data":_vm.data.marketRelathionship}})],1),_c('b-col',{staticClass:"mt-4",attrs:{"md":"12"}},[_c('Relationship',{attrs:{"title":"Relacionamento com Factoring","data":_vm.data.factoryRelathionship}})],1)],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('h4',[_vm._v(" Fornecedores ")]),_c('b-table',{staticClass:"text-center",attrs:{"fields":[
          { key: 'name', label: 'nome' },
          { key: 'taxId', label: 'CNPJ' }
        ],"items":_vm.data.suppliers},scopedSlots:_vm._u([{key:"cell(taxId)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("tax_id")(data.item.taxId))+" ")]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('serasa-table',{attrs:{"title":"Pefin","fields":[
          { key: 'date', label: 'Data' },
          { key: 'value', label: 'Valor' },
          { key: 'contract', label: 'Contrato' },
          { key: 'origin', label: 'Origem'}
        ],"items":_vm.data.pefin,"desc-values":_vm.pefin}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('serasa-table',{attrs:{"title":"Refin","fields":[
          { key: 'date', label: 'Data' },
          { key: 'value', label: 'Valor' },
          { key: 'contract', label: 'Contrato' },
          { key: 'origin', label: 'Origem'}
        ],"items":_vm.data.refin,"desc-values":_vm.refin}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('serasa-table',{attrs:{"title":"Protesto","fields":[
          { key: 'date', label: 'Data' },
          { key: 'value', label: 'Valor' },
          { key: 'state', label: 'Estado'},
          { key: 'city', label: 'Cidade' } ],"items":_vm.data.protest,"desc-values":_vm.protest}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('serasa-table',{attrs:{"title":"Ação Judicial","fields":[
          { key: 'date', label: 'Data' },
          { key: 'value', label: 'Valor' },
          { key: 'nature', label: 'natureza' },
          { key: 'city', label: 'Cidade'}
        ],"items":_vm.data.lawsuit,"desc-values":_vm.lawSuit}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('serasa-table',{attrs:{"title":"Divida Vencida","fields":[
          { key: 'date', label: 'Data' },
          { key: 'value', label: 'Valor' },
          { key: 'origin', label: 'Cobrador' } ],"items":_vm.data.expiredDebts.map(function (item) { return (Object.assign({}, item,
          {currency: 'R$'})); }),"desc-values":_vm.expiredDebts}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('serasa-table',{attrs:{"title":"Cheque Sem Fundo","fields":[
          { key: 'date', label: 'Data' },
          { key: 'value', label: 'Valor' },
          { key: 'check', label: 'Número' },
          { key: 'city', label: 'Cidade'}
        ],"items":_vm.data.badCheck,"desc-values":_vm.badCheck}})],1)],1),_c('b-row',{staticClass:"mt-2 px-1"},[_c('h4',[_vm._v(" Histórico de Pagamento")]),_c('b-col',{attrs:{"md":"12"}},_vm._l((_vm.data.paymentHistoric),function(item,key){return _c('PaymentHistoric',{key:key,attrs:{"data":item,"title":key}})}),1),_c('b-col',{attrs:{"md":"12"}},[_c('PaymentHistoric',{attrs:{"data":_vm.data.paymentHistoricAverage,"title":"MÉDIA"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }