<template>
  <div>
    <b-form>
      <b-row>
        <b-col
          class="mx-auto"
          md="4"
        >
          <b-form-group label="Consulta">
            <b-form-select
              v-model="scr"
              :options="options"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <div
      v-if="scr"
      :key="this.componentKey"
    >
      <p> Período: {{ scr.Periodo }}</p>
      <SCR
        :information="scr"
      />
    </div>

  </div>
</template>

<script>
import {
    BForm, BFormSelect, BFormGroup, BCol, BRow,
} from 'bootstrap-vue';
import SCR from '../../../../scr/components/View.vue';

import ScrService from '@/service/scr';

export default {
    components: {
        SCR,
        BForm,
        BCol,
        BRow,
        BFormSelect,
        BFormGroup,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            scrs: [],
            scr: null,
            componentKey: 1,
        };
    },

    computed: {
        options() {
            return this.scrs.map(item => ({
                value: item,
                text: item.Periodo,
            })).sort((a, b) => a.text.localeCompare(b.text));
        },
    },

    watch: {
        data() {
            this.get();
            this.scr = null;
        },

        scr() {
            this.componentKey++;
        },
    },

    async mounted() {
        this.get();
    },

    methods: {
        async get() {
            const { data } = await ScrService.index({
                tax_id: this.data.company.tax_id,
            });

            this.scrs = data;
            this.scr = data.find(item => JSON.stringify(item) === JSON.stringify(this.data.scr.scr_analyse.scr.body)) || null;
        },
    },
};
</script>
