<template>
  <div>
    <TableGrade
      grade-field="Resultado"
      :items="analyse"
      :fields="[
        { key: 'index', label: 'Índice'},
      ]"
    />
  </div>
</template>

<script>
import TableGrade from '../../TableGrade.vue';

export default {
    components: {
        TableGrade,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    computed: {
        analyse() {
            if (!this.data?.index) return [];
            const data = this.data.index.map(item => item.analyse);
            const item = data[data.length - 1];

            return Object.entries(item).map(([key, value]) => ({
                index: key,
                grade: value,
            }));
        },
    },
};
</script>
