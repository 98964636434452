<template>
  <div>
    <b-col
      class="mx-auto"
      md="4"
    >
      <b-form-group>
        <b-form-select
          v-model="serasa"
          :options="options"
        />
      </b-form-group>
    </b-col>
    <b-tabs
      v-if="serasa"
      justified
      align="center"
    >
      <b-tab title="Relato">
        <serasa-page :data="serasa.report" />
      </b-tab>
      <b-tab title="Bureau">
        <serasa-bureau-page :data="serasa.restriction_partners" />
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
import {
    BTabs, BTab, BFormGroup, BFormSelect, BCol,
} from 'bootstrap-vue';

import { DateTime } from 'luxon';
import SerasaPage from './Report/Page.vue';
import SerasaBureauPage from './Bureau/Page.vue';

import BalanceService from '@/service/balance';

export default {
    components: {
        BTabs,
        BTab,
        SerasaPage,
        SerasaBureauPage,
        BFormGroup,
        BFormSelect,
        BCol,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            serasas: [],
            serasa: null,
            serasaGroup: null,
        };
    },

    computed: {
        options() {
            return this.serasas.map(item => ({
                value: item,
                text: DateTime.fromISO(item.created_at).toFormat('dd/MM/yyyy'),
            }));
        },
    },

    async mounted() {
        const { data } = await BalanceService.viewSerasa(this.data.company.tax_id);
        this.serasas = data.data;

        const id = data.data.find(item => JSON.stringify(item.report) === JSON.stringify(this.data.serasa.report));
        this.serasa = id || null;
    },
};
</script>
