export default {
    floating: [
        { key: 'suppliers', label: 'Fornecedores' },
        { key: 'loans_and_financing', label: 'Emprestimos e Financiamentos' },
        { key: 'taxes_payable', label: 'Impostos a Recolher' },
        { key: 'accounts_receivable', label: 'Contas a Pagar' },
        { key: 'unsecured_rj', label: 'Quirografarios RJ' },
        { key: 'committed_properties', label: 'Imoveis Compromissados' },
        { key: 'related_parties', label: 'Partes Relacionadas' },
        { key: 'debentures', label: 'Debentures' },
        { key: 'dividends_payable', label: 'Dividendos a Pagar' },
        { key: 'provision_for_losses_in_legal_proceedings', label: 'Provisão para perdas em processos judiciais' },
        { key: 'intercompany', label: 'Intercompany' },
        { key: 'personnel_obligations', label: 'Obrigações com Pessoal' },
        { key: 'leases', label: 'Arrendamentos' },
        { key: 'customer_advances', label: 'Adiantamento de Clientes' },
        { key: 'provisions', label: 'Provisoes' },
        { key: 'personnel_obligations', label: 'Obrigações com Pessoal' },
        { key: 'others', label: 'Outros' },
    ],

    longTermRequired: [
        { key: 'loans_and_financing', label: 'Empréstimos e Financiamentos' },
        { key: 'taxes_payable', label: 'Impostos a Recolher' },
        { key: 'dividends_payable', label: 'Dividendos a Pagar ' },
        { key: 'commited_properties', label: 'Imoveis Compromissados' },
        { key: 'related_parties', label: 'Partes Relacionadas' },
        { key: 'provision_for_losses_in_legal_proceedings', label: 'Provisão para perdas em processos judiciais' },
        { key: 'tax_installments', label: 'Parcelamentos de Tributos' },
        { key: 'debentures', label: 'Debentures' },
        { key: 'unsecured_rj', label: 'Quirografarios RJ' },
        { key: 'suppliers', label: 'Fornecedores' },
        { key: 'leases', label: 'Arrendamentos' },
        { key: 'deferred_taxes', label: 'Impostos Deferidos' },
        { key: 'others', label: 'Outros' },
    ],

    netWorth: [],
};
