<template>
  <card-header>

    <SerasaSummary :data="data" />

    <b-row class="mt-4">
      <b-col md="3">
        <h4> Consultas </h4>
        <b-table
          :items="data.consults"
          class="text-center"
          :fields="[
            { key: 'date', label: 'DATA'},
            { key: 'companyAmount', label: 'Quantidade'}
          ]"
        >
          <template #cell(date)="data">
            {{ data.item.month }} / {{ data.item.year }}
          </template>
        </b-table>
      </b-col>

      <b-col md="9">
        <b-row>
          <b-col md="12">
            <h4> Últimas Consultas </h4>
            <b-table
              :items="data.lastConsult"
              class="text-center"
              :fields="[
                { key: 'date', label: 'DATA'},
                { key: 'customerName', label: 'Empresa'},
                { key: 'amount', label: 'Quantidade'}
              ]"
            >
              <template #cell(date)="data">
                {{ data.item.date | date }}
              </template>
            </b-table>
          </b-col>

          <b-col
            class="mt-4"
            md="12"
          >
            <Relationship
              title="Relacionamento com Mercado"
              :data="data.marketRelathionship"
            />
          </b-col>

          <b-col
            class="mt-4"
            md="12"
          >
            <Relationship
              title="Relacionamento com Factoring"
              :data="data.factoryRelathionship"
            />
          </b-col>

        </b-row>

      </b-col>

    </b-row>

    <b-row
      class="mt-2"
    >

      <b-col md="12">
        <h4> Fornecedores </h4>
        <b-table
          :fields="[
            { key: 'name', label: 'nome' },
            { key: 'taxId', label: 'CNPJ' }
          ]"
          class="text-center"
          :items="data.suppliers"
        >
          <template #cell(taxId)="data">
            {{ data.item.taxId | tax_id }}
          </template>
        </b-table>
      </b-col>

      <b-col
        md="6"
      >
        <serasa-table
          title="Pefin"
          :fields="[
            { key: 'date', label: 'Data' },
            { key: 'value', label: 'Valor' },
            { key: 'contract', label: 'Contrato' },
            { key: 'origin', label: 'Origem'}
          ]"
          :items="data.pefin"
          :desc-values="pefin"
        />

      </b-col>

      <b-col md="6">
        <serasa-table
          title="Refin"
          :fields="[
            { key: 'date', label: 'Data' },
            { key: 'value', label: 'Valor' },
            { key: 'contract', label: 'Contrato' },
            { key: 'origin', label: 'Origem'}
          ]"
          :items="data.refin"
          :desc-values="refin"
        />
      </b-col>

      <b-col
        md="6"
      >
        <serasa-table
          title="Protesto"
          :fields="[
            { key: 'date', label: 'Data' },
            { key: 'value', label: 'Valor' },
            { key: 'state', label: 'Estado'},
            { key: 'city', label: 'Cidade' },
          ]"
          :items="data.protest"
          :desc-values="protest"
        />
      </b-col>

      <b-col md="6">
        <serasa-table
          title="Ação Judicial"
          :fields="[
            { key: 'date', label: 'Data' },
            { key: 'value', label: 'Valor' },
            { key: 'nature', label: 'natureza' },
            { key: 'city', label: 'Cidade'}
          ]"
          :items="data.lawsuit"
          :desc-values="lawSuit"
        />
      </b-col>

      <b-col md="6">
        <serasa-table
          title="Divida Vencida"
          :fields="[
            { key: 'date', label: 'Data' },
            { key: 'value', label: 'Valor' },
            { key: 'origin', label: 'Cobrador' },
          ]"
          :items="data.expiredDebts.map(item => ({
            ...item,
            currency: 'R$'
          }))"
          :desc-values="expiredDebts"
        />
      </b-col>

      <b-col md="6">
        <serasa-table
          title="Cheque Sem Fundo"
          :fields="[
            { key: 'date', label: 'Data' },
            { key: 'value', label: 'Valor' },
            { key: 'check', label: 'Número' },
            { key: 'city', label: 'Cidade'}
          ]"
          :items="data.badCheck"
          :desc-values="badCheck"
        />
      </b-col>
    </b-row>

    <b-row class="mt-2 px-1">
      <h4> Histórico de Pagamento</h4>
      <b-col md="12">
        <PaymentHistoric
          v-for="(item, key) in data.paymentHistoric"
          :key="key"
          :data="item"
          :title="key"
        />
      </b-col>

      <b-col md="12">
        <PaymentHistoric
          :data="data.paymentHistoricAverage"
          title="MÉDIA"
        />
      </b-col>
    </b-row>
  </card-header>
</template>

<script>
import { BCol, BRow, BTable } from 'bootstrap-vue';

import CardHeader from '../../Card/CardHeader.vue';

import SerasaSummary from './ReportSummary.vue';
import SerasaTable from '../Table.vue';

import PaymentHistoric from '../PaymentHistoric.vue';

import Relationship from './Relantionship.vue';

export default {
    components: {
        BCol, CardHeader, SerasaSummary, SerasaTable, BRow, PaymentHistoric, BTable, Relationship,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    computed: {
        pefin() {
            return this.getResume('PEFIN');
        },

        refin() {
            return this.getResume('REFIN');
        },

        protest() {
            return this.getResume('PROTESTO');
        },

        lawSuit() {
            return this.getResume('ACAO JUDICIAL');
        },

        expiredDebts() {
            return this.getResume('DIVIDA VENCIDA');
        },

        total() {
            return this.data.factoryRelathionship.reduce((acc, item) => acc + item.amount, 0);
        },
    },

    methods: {
        getResume(key) {
            const data = this.data.resumeFinancialPendency.find(item => item.disc === key);

            return {
                value: data?.value || 0,
                quantity: data?.amount || 0,
            };
        },
    },
};
</script>
