<template>
  <ViewBalance
    v-if="!hide"
    :key="componentKey"
    :balance-data="data"
    @ready="getData"
    @reload="getData"
  />
</template>

<script>
import BalanceService from '@/service/balance';

import ViewBalance from './components/View.vue';

export default {
    components: { ViewBalance },

    data() {
        return {
            data: null,
            company: null,
            componentKey: 1,
            hide: this.$route.params.id,
        };
    },

    async mounted() {
        const { id } = this.$route.params;
        if (id) {
            await this.getData(id);
        }
    },

    methods: {
        async getData(fileId) {
            this.callLoading(true);
            const { data } = await BalanceService.viewBalances(fileId);
            this.data = data;

            this.reRender();
            this.hide = false;
            this.callLoading(false);
        },

        reRender() {
            this.componentKey++;
        },
    },
};
</script>
