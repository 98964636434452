<template>
  <b-form>
    <b-row class="justify-content-center">
      <b-col md="3">
        <b-form-group
          label-for="company"
          label="Empresa"
        >
          <multiselect
            v-model="company"
            :options="companiesOptions"
            :multiple="false"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Selecione uma empresa"
            select-label=""
            selected-label=""
            deselect-label=""
            label="name"
            track-by="name"
          >

            <span slot="noResult">
              Nenhum Produto Encontrado!
            </span>
          </multiselect>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group
          label-for="file"
          label="Arquivo"
        >
          <b-form-select
            id="file"
            v-model="file"
            :disabled="!company"
            :options="fileOptions"
          />
        </b-form-group>
      </b-col>

      <b-col md="12">
        <b-button
          variant="primary"
          class="mx-auto d-block"
          @click="() => {
            $emit('button-clicked', file)
          }
          "
        >
          Visualizar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
    BForm, BFormGroup, BFormSelect, BRow, BCol, BButton,
} from 'bootstrap-vue';

import Multiselect from 'vue-multiselect';
import BalanceService from '@/service/balance';

export default {
    components: {
        BButton, BForm, BFormGroup, BFormSelect, BRow, BCol, Multiselect,
    },

    props: {
        companyId: { type: Number, default: () => 0 },
        fileId: { type: Number, default: () => 0 },
    },

    data() {
        return {
            company: null,
            companies: [],
            files: [],
            file: null,
        };
    },

    computed: {
        companiesOptions() {
            const options = this.companies.map(item => ({
                value: item.id,
                name: item.legal_tax_name || item.tax_id,
            }));

            return options.sort((a, b) => a.name.localeCompare(b.name));
        },

        fileOptions() {
            const options = this.files.map(item => ({
                value: item.id,
                text: item.file.file_name,
            }));

            options.unshift({ value: null, text: 'Selecione um arquivo' });

            return options;
        },
    },

    watch: {
        company() {
            this.getBalanceFilesByCompanyId();
        },
    },

    async mounted() {
        this.callLoading(true);
        await this.getAllCompanies();
        this.callLoading(false);

        this.company = this.companiesOptions.find(item => item.value === this.companyId) || null;
        this.file = this.fileId || null;
    },

    methods: {
        async getAllCompanies() {
            const { data } = await BalanceService.viewAllBalancesCompanies();
            this.companies = data;
        },

        async getBalanceFilesByCompanyId() {
            const { data } = await BalanceService.viewAllBalanceFileByCompanyId(this.company.value);
            this.files = data.data;
        },
    },
};
</script>

<style lang="scss">

.multiselect__option--highlight {
    background: #2E2C6C !important;
}

.multiselect__tag {
    background: #2E2C6C !important;
}

.dark-layout {
    .multiselect__tags {
        background-color: #283046;
        border-color: #3b4253;
    }
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
