import { types } from '../../translate-object';

export const propertiesIndexCapitalStructure = {
    third_party_capital_participation: {
        name: 'Particip.capital de terceiros',
        type: types.alreadyInPercent,
    },
    third_party_capital_guarantee: {
        name: 'Garantia capital de terceiros',
        type: types.alreadyInPercent,
    },
    demands_composition: {
        name: 'Composição das exigibilidades',
        type: types.alreadyInPercent,
    },
    pl_imobilization: {
        name: 'Imobilização do PL',
        type: types.alreadyInPercent,
    },
    property_asset_obsolescence: {
        name: 'Obsolência do Ativo Imob.',
        type: types.alreadyInPercent,
    },
};

export const propertiesIndexLiquidity = {
    general: {
        name: 'Liquidez Geral',
        type: types.default,
    },
    current: {
        name: 'Liquidez Corrente',
        type: types.default,
    },
    dry: {
        name: 'Liquidez Seca',
        type: types.default,
    },
    cash_cycle: {
        name: 'Capital de Giro Próprio',
        type: types.currency,
    },
};

export const propertiesIndexResult = {
    asset_turnover: {
        name: 'Giro do Ativo',
        type: types.default,
    },
    return_asset: {
        name: 'Rentabilidade do Ativo',
        type: types.alreadyInPercent,
    },
    sales_profitability: {
        name: 'Rentabilidade de vendas',
        type: types.alreadyInPercent,
    },
    real_sales_perfomance: {
        name: 'Evolução Real das Vendas',
        type: types.alreadyInPercent,
    },
};
