<template>
  <div>
    <h5> {{ title }}</h5>
    <b-table
      class="text-center"
      :fields="[
        { key: 'period', label: 'Média Pontual'},
        { key: 'code', label: 'Código'},
        { key: 'valueRange', label: 'Faixa de Valor'},
        { key: 'valuePercentRange', label: 'Faixa de Valor'}
      ]"
      :items="data"
    >
      <template #cell(valueRange)="data">
        {{ data.item.paymentValueFrom | value }} a {{ data.item.paymentValueTo | value }}
      </template>

      <template #cell(valuePercentRange)="data">
        {{ data.item.percentPaymentValueFrom / 100 | percent }} a {{ data.item.percentPaymentValueTo / 100 | percent }}
      </template>

      <template #cell(code)="data">
        {{ data.item.code || '---' }}
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue';

export default {
    components: {
        BTable,
    },

    props: {
        data: {
            type: Array,
            default: () => {},
        },

        title: {
            type: String,
            required: true,
        },
    },
};
</script>
