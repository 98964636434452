<template>
  <div>

    <b-tabs
      align="center"
      justified
    >
      <b-tab>
        <template #title>
          <span class="font-weight-bold">Ativo</span>
        </template>
        <active :data="balance.file.balance_active" />
      </b-tab>

      <b-tab>
        <template #title>
          <span class="font-weight-bold">Passivo</span>
        </template>
        <passive :data="balance.file.balance_passive" />
      </b-tab>

      <b-tab>
        <template #title>
          <span class="font-weight-bold">DRE</span>
        </template>
        <dre :data="balance.file.balance_dre" />
      </b-tab>
    </b-tabs>
  </div></template>

<script>
import { BTabs, BTab } from 'bootstrap-vue';
import Active from './Active.vue';
import Dre from './Dre.vue';
import Passive from './Passive.vue';

export default {
    components: {
        Active, BTabs, BTab, Dre, Passive,
    },

    props: {
        balance: {
            type: Object,
            required: true,
        },
    },
};
</script>
