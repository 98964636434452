export default {
    floating: [
        { key: 'available', label: 'Disponível' },
        { key: 'accounts_receivable', label: 'Contas a Receber  (Clientes)' },
        { key: 'discounted_bills', label: '(-) Títulos Descontados' },
        { key: 'doubtful_debt_provision', label: '(-) Provisão Devedores Duvidosos' },
        { key: 'inventory', label: 'Estoques' },
        { key: 'advance_to_suppliers', label: 'Adianamento a Fornecedores' },
        { key: 'advances_to_employees', label: 'Adiantamento a Empregados' },
        { key: 'financial_instruments', label: 'Instrumentos Financeiros' },
        { key: 'related_parties', label: 'Partes Relacionadas' },
        { key: 'recoverable_tax', label: 'Imposto a recuperar' },
        { key: 'securities_and_financial_instruments', label: 'Titulos e Valores Mobiliarios' },
        { key: 'expenses_next_period', label: 'Despesas do Exercício seguinte' },
        { key: 'others', label: 'Outros' },
    ],

    achievable_long_term: [
        { key: 'accounts_receivable', label: 'Contas a Receber' },
        { key: 'financial_investments', label: 'Aplicações Financeiras' },
        { key: 'related_parties', label: 'Partes Relacionadas' },
        { key: 'goods_intended_for_sale', label: 'Bens Destinados a Venda' },
        { key: 'deferred_taxes', label: 'Tributos Deferidos' },
        { key: 'marketable_securities', label: 'Títulos e Valores Mobiliários' },
        { key: 'others', label: 'Outros' },
    ],

    permanent: [
        { key: 'investments', label: 'Investimentos' },
        { key: 'fixed_assets', label: 'Imobilizado' },
        { key: 'depreciation', label: '(-) Depreciação' },
        { key: 'right_to_use_assets', label: 'Direito de uso dos ativos' },
        { key: 'other_intangible', label: 'Outros/Intangivel' },
    ],

};
