<template>
  <div>
    <CardHeader title="Estrutura de Capitais">
      <Table
        :properties="propertiesIndexCapitalStructure"
        :data="data.index.map(item => item.index_capital_structure)"
        :dates="dates"
      />
    </CardHeader>

    <CardHeader
      title="Liquidez"
    >
      <Table
        :properties="propertiesIndexLiquidity"
        :data="data.index.map(item => item.index_liquidity)"
        :dates="dates"
      />
    </CardHeader>

    <CardHeader
      title="Resultado"
    >
      <Table
        :properties="propertiesIndexResult"
        :data="data.index.map(item => item.result)"
        :dates="dates"
      />
    </CardHeader>
  </div>
</template>

<script>
import Table from '../../Table.vue';

import { propertiesIndexCapitalStructure, propertiesIndexLiquidity, propertiesIndexResult } from '../properties';

import CardHeader from '../../Card/CardHeader.vue';

export default {
    components: {
        Table,
        CardHeader,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            propertiesIndexCapitalStructure,
            propertiesIndexLiquidity,
            propertiesIndexResult,
        };
    },

    computed: {
        dates() {
            return this.data?.index.map(item => item.date) || [];
        },
    },
};
</script>
