<template>
  <div>
    <b-table
      :items="tableItems"
      class="text-center table-grade"
      :fields="fieldsWithGrade"
    >
      >
      <template #cell(grade)="data">
        <div :class="`bg-${data.item.variant} text-${data.item.textColor} py-1`">
          <span
            style="font-weight: bold; font-size: 12"
            class="font-bold"
          >
            {{ data.item.message }}
          </span>
        </div>
      </template>

      <template #cell(index)="data">
        <div v-if="tableItems.length === data.index+1">
          <h6>
            {{ data.item.index }}
          </h6>
        </div>

        <div v-else>
          {{ data.item.index }}
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue';

import { gradeColor } from './grade-color';

const translate = {
    current_liquidity: 'Liquidez Corrente',
    dry_liquidation: 'Liquidez Seca',
    general_liquidity: 'Liquidez Geral',
    short_term_debt: 'Endividamento a Curto Prazo',
    total_debt_value: 'Grau de Endividamento Total',
    result: 'Conceito Total',
};

export default {
    components: {
        BTable,
    },

    props: {
        fields: {
            type: Array,
            required: true,
        },

        items: {
            type: Array,
            required: true,
        },

        gradeField: {
            type: String,
            default: () => 'Indicador',
        },
    },

    data() {
        return {
            gradeColor,
        };
    },

    computed: {
        fieldsWithGrade() {
            return [
                ...this.fields,
                { key: 'grade', label: this.gradeField },
            ];
        },

        tableItems() {
            return this.items.filter(item => translate[item.index]).map(item => {
                const grade = this.gradeColor[item.grade];

                if (!grade) return {};

                return {
                    variant: grade.background,
                    message: grade.message,
                    textColor: grade.text,
                    index: translate[item.index],
                };
            });
        },
    },
};
</script>

<style>
table.table-grade tbody tr td {
    padding: 0 !important;
}
</style>
