<template>
  <div>
    <b-table
      class="text-center"
      :fields="fields"
      :items="items"
    >
      <template v-slot:cell(title)="data">
        <span :class="data.item.bold ? 'font-weight-bold' : ''">
          {{ data.item.title }}
        </span>
      </template>

      <template v-slot:cell(value1)="data">
        <span
          v-if="data.item.value1 !== null && data.item.value1 !== undefined"
          :class="data.item.bold ? 'font-weight-bold' : ''"
        >
          {{ data.item.value1 | value }}
        </span>

        <span v-else> ------ </span>
      </template>

      <template v-slot:cell(value2)="data">
        <span
          v-if="data.item.value2 !== null && data.item.value2 !== undefined"
          :class="data.item.bold ? 'font-weight-bold' : ''"
        >
          {{ data.item.value2 | value }}
        </span>

        <span v-else> ------ </span>
      </template>

      <template v-slot:cell(value3)="data">
        <span
          v-if="data.item.value3 !== null && data.item.value3 !== undefined"
          :class="data.item.bold ? 'font-weight-bold' : ''"
        >
          {{ data.item.value3 | value }}
        </span>

        <span v-else> ------ </span>
      </template>
    </b-table>

  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue';

export default {
    components: {
        BTable,
    },

    props: {
        items: {
            type: Array,
            default: () => [],
        },

        fields: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
