<template>
  <div>
    <b-form-select
      v-model="group"
      :options="data.group.map(item => ({
        text: item.report.company.legalTaxName,
        value: item
      }))"
    />
    <b-tabs
      v-if="group"
      :key="componentKey"
      justified
      align="center"
    >
      <b-tab title="Relato">
        <serasa-page
          :data="group.report"
        />
      </b-tab>

      <b-tab title="SCR">
        <scr
          :information="group.scr.scr.body"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
    BFormSelect, BTab, BTabs,
} from 'bootstrap-vue';

import SerasaPage from '../Serasa/Report/Page.vue';
import Scr from '../../../../scr/components/View.vue';

export default {
    components: {
        BFormSelect, BTab, BTabs, SerasaPage, Scr,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            group: null,
            componentKey: 1,
        };
    },

    watch: {
        group() {
            // eslint-disable-next-line no-plusplus
            this.componentKey++;
        },
    },
};
</script>
