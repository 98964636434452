<template>
  <div>
    <div class="d-flex justify-content-between">
      <h4> {{ title }}</h4>
      <h4> Fontes Consultadas: {{ total }}</h4>
    </div>

    <b-row class="justify-content-around">
      <div
        v-for="item of data"
      >
        <div class="d-flex flex-column">
          <span> {{ item.period }} </span>
          <span class="text-center"> {{ item.amount }} </span>
        </div>
      </div>
      <span />
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow, BTable } from 'bootstrap-vue';

import CardHeader from '../../Card/CardHeader.vue';

import SerasaSummary from './ReportSummary.vue';
import SerasaTable from '../Table.vue';

import PaymentHistoric from '../PaymentHistoric.vue';

export default {
    components: {
        BCol, CardHeader, SerasaSummary, SerasaTable, BRow, PaymentHistoric, BTable,
    },
    props: {
        data: {
            type: Array,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },
    },

    computed: {
        total() {
            return this.data.reduce((acc, item) => acc + item.amount, 0);
        },
    },

    methods: {
        getResume(key) {
            const data = this.data.resumeFinancialPendency.find(item => item.disc === key);

            return {
                value: data?.value || 0,
                quantity: data?.amount || 0,
            };
        },
    },
};

</script>
