<template>
  <b-tabs
    v-if="data"
    justified
    align="center"
  >
    <b-tab title="Indice">
      <Index :data="data" />
    </b-tab>

    <b-tab title="Análise dos Índices">
      <IndexAnalyse :data="data" />
    </b-tab>

    <b-tab
      v-if="data.index.some(item => item.insolvency_factor)"
      title="Fator de Insolvência - Kanitz"
    >
      <InsolvencyFactor :data="data" />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue';

import Index from './Tabs/Index.vue';
import IndexAnalyse from './Tabs/IndexAnalyse.vue';
import InsolvencyFactor from './Tabs/InsolvencyFactor.vue';

export default {
    components: {
        Index,
        IndexAnalyse,
        BTabs,
        BTab,
        InsolvencyFactor,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    computed: {
        dates() {
            return this.data?.index.map(item => item.date) || [];
        },
    },
};
</script>
