<template>
  <div>
    <b-form>
      <b-row>
        <b-col
          class="mx-auto"
          md="4"
        >
          <b-form-group
            label="Sócio com Restrição"
            label-for="partner-with-restriction"
          >
            <b-form-select
              id="partner-with-restriction"
              v-model="partner"
              :options="partners"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <b-row v-if="partner">
      <b-col md="12">
        <SerasaSummary
          :pefin="pefin"
          :bad-check="!!partner.badCheck.length"
          :refin="refin"
          :protest="protest"
          :law-suit="lawSuit"
          :has-injuction="data.hasInjuction"
          :expired-debts="expiredDebts"
        />
      </b-col>

      <b-col
        md="6"
      >
        <serasa-table
          title="Pefin"
          :fields="[
            { key: 'date', label: 'Data' },
            { key: 'value', label: 'Valor' },
            { key: 'contract', label: 'Contrato' },
            { key: 'origin', label: 'Origem'}
          ]"
          :items="partner.pefin"
          :desc-values="pefin"
        />

      </b-col>

      <b-col md="6">
        <serasa-table
          title="Refin"
          :fields="[
            { key: 'date', label: 'Data' },
            { key: 'value', label: 'Valor' },
            { key: 'contract', label: 'Contrato' },
            { key: 'origin', label: 'Origem'}
          ]"
          :items="partner.refin"
          :desc-values="refin"
        />
      </b-col>

      <b-col
        md="6"
      >
        <serasa-table
          title="Protesto"
          :fields="[
            { key: 'date', label: 'Data' },
            { key: 'value', label: 'Valor' },
            { key: 'state', label: 'Estado'},
            { key: 'city', label: 'Cidade' },
          ]"
          :items="partner.protest"
          :desc-values="protest"
        />
      </b-col>

      <b-col md="6">
        <serasa-table
          title="Ação Judicial"
          :fields="[
            { key: 'date', label: 'Data' },
            { key: 'value', label: 'Valor' },
            { key: 'nature', label: 'natureza' },
            { key: 'city', label: 'Cidade'}
          ]"
          :items="partner.lawSuit"
          :desc-values="lawSuit"
        />
      </b-col>

      <b-col md="6">
        <serasa-table
          title="Dívida Vencida"
          :fields="[
            { key: 'date', label: 'Data' },
            { key: 'value', label: 'Valor' },
            { key: 'name', label: 'nome' },
            { key: 'city', label: 'Cidade'}
          ]"
          :items="partner.expiredDebts"
          :desc-values="expiredDebts"
        />
      </b-col>

      <b-col md="6">
        <serasa-table
          title="Cheque Sem Fundo"
          :fields="[
            { key: 'date', label: 'Data' },
            { key: 'value', label: 'Valor' },
            { key: 'check', label: 'Número' },
            { key: 'city', label: 'Cidade'}
          ]"
          :items="partner.badCheck"
          :desc-values="badCheck"
        />
      </b-col>

      <b-col
        class="mt-4"
        md="12"
      >
        <h4> Participação Societária </h4>
        <b-table
          class="text-center"
          :fields="[
            { key: 'legalTaxName', label: 'Razão Social'},
            { key: 'taxId', label: 'CNPJ' },
            { key: 'participationPercentage', label: 'Porcentagem de Participação'},
            { key: 'state', label: 'Estado' },
            { key: 'participationInitDate', label: 'Desde'},
            { key: 'lastUpdate', label: 'Atualização'}
          ]"
          :items="partner.companyParticipation"
        >
          <template #cell(participationInitDate)="data">
            {{ formatDate(data.item.participationInitDate ) }}
          </template>

          <template #cell(lastUpdate)="data">
            {{ formatDate(data.item.lastUpdate ) }}
          </template>

          <template #cell(participationPercentage)="data">
            {{ data.item.participationPercentage / 10 + '%' }}
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
    BForm, BFormInput, BFormSelect, BFormGroup, BRow, BCol, BTable,
} from 'bootstrap-vue';

import { DateTime } from 'luxon';
import SerasaSummary from '../Summary.vue';

import SerasaTable from '../Table.vue';

export default {
    components: {
        BForm, BFormGroup, BFormSelect, BRow, BCol, SerasaSummary, SerasaTable, BTable,
    },

    props: {
        data: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            partner: null,
        };
    },

    computed: {
        partners() {
            return this.data.map(item => ({
                text: item.spelling,
                value: item,
            }));
        },

        pefin() {
            return this.count('pefin');
        },

        refin() {
            return this.count('refin');
        },

        protest() {
            return this.count('protest');
        },

        lawSuit() {
            return this.count('lawSuit');
        },

        expiredDebts() {
            return this.count('expiredDebts');
        },

        badCheck() {
            return this.count('badCheck');
        },
    },

    methods: {
        count(key) {
            const data = this.partner[key];
            return {
                quantity: data.length,
                value: data.reduce((acc, item) => item.value + acc, 0),
            };
        },

        formatDate(date) {
            return DateTime.fromFormat(date, 'yyyyMM').toFormat('MM/yyyy');
        },
    },
};
</script>
