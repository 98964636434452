<template>
  <SerasaSummary
    :class="`text-${align}`"
    :pefin="pefin"
    :refin="refin"
    :law-suit="lawSuit"
    :expired-debts="expiredDebts"
    :protest="protest"
    :has-injuction="data.messageDebts.hasInjuction"
    :bad-check="!!data.badCheck.length"
  />
</template>

<script>
import SerasaSummary from '../Summary.vue';

export default {

    components: {
        SerasaSummary,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },

        align: {
            type: String,
            default: () => 'center',
        },
    },
    computed: {
        pefin() {
            return this.getResume('PEFIN');
        },

        refin() {
            return this.getResume('REFIN');
        },

        protest() {
            return this.getResume('PROTESTO');
        },

        lawSuit() {
            return this.getResume('ACAO JUDICIAL');
        },

        expiredDebts() {
            return this.getResume('DIVIDA VENCIDA');
        },
    },

    methods: {
        getResume(key) {
            const data = this.data.resumeFinancialPendency.find(item => item.disc === key);

            return {
                value: data?.value || 0,
                quantity: data?.amount || 0,
            };
        },
    },
};
</script>
