<!-- eslint-disable no-restricted-syntax -->
<!-- eslint-disable no-restricted-syntax -->
<template>
  <div>

    <Table
      v-for="(tableKey, index) in tables"
      :key="index"
      class="mb-4"
      :fields="fields"
      :items="doItems(data, tableKey)"
    />

  </div>
</template>

<script>
import { DateTime } from 'luxon';

import Table from './Table.vue';
import layout from './layout-dre';

export default {
    components: {
        Table,
    },

    props: {
        data: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            tables: Object.keys(layout),
        };
    },

    computed: {
        fields() {
            const defaultFields = [
                { key: 'title', label: 'DRE' },
            ];

            this.dataFormatted.forEach((item, index) => {
                if (+item.net_profit) {
                    defaultFields.push({
                        key: `value${index + 1}`,
                        label: DateTime.fromISO(item.date).plus({ days: 1 }).toFormat(
                            'dd/MM/yyyy',
                        )
                        ,
                    });
                }
            });

            return defaultFields;
        },

        dataFormatted() {
            return this.data;
        },
    },

    methods: {
        doItems(table, key) {
            const dates = this.dataFormatted.map((item, index) => ({
                key: `value${index + 1}`,
                label: DateTime.fromISO(item.date).toFormat(
                    'dd/MM/yyyy',
                ),
            }));

            const dataCompleted = [];

            // eslint-disable-next-line no-restricted-syntax
            for (const iterator of Object.values(layout[key])) {
                const obj = {
                    title: iterator.label,
                    bold: iterator.bold,
                };

                // eslint-disable-next-line no-restricted-syntax
                for (const dateIndex in dates) {
                    const date = dates[dateIndex];
                    const result = this.findFieldValue(table[dateIndex], iterator.key);
                    obj[date.key] = result;
                }

                dataCompleted.push(obj);
            }

            return dataCompleted;
        },
        findFieldValue(data, fieldName) {
            const result = null;

            // eslint-disable-next-line no-restricted-syntax
            for (const key of Object.keys(data)) {
                const currentData = data[key];

                if (key === fieldName) {
                    return currentData;
                }

                if (typeof currentData === 'object' && currentData) {
                    const value = this.findFieldValue(currentData, fieldName);

                    if (value) {
                        return value;
                    }
                }
            }

            return result;
        },
    },

};
</script>
