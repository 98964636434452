<template>
  <b-card>
    <b-form>
      <b-form-textarea
        id="textarea-no-resize"
        v-model="description"
        rows="20"
        placeholder="Parecer de Crédito"
        no-resize
      />

      <b-button
        variant="primary"
        class="mt-2"
        @click="addOpinion"
      >
        Adicionar
      </b-button>
    </b-form>
  </b-card>
</template>

<script>
import {
    BCard, BForm, BFormInput, BButton, BRow, BCol, BFormGroup, BFormTextarea,
} from 'bootstrap-vue';

import BalanceService from '@/service/balance';

export default {

    components: {
        BCard, BForm, BFormTextarea, BButton,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            description: this.data?.balance_message?.opinion || '',
        };
    },

    methods: {
        async addOpinion() {
            const isConfirmed = await this.confirmAnAction('Deseja realmente adicionar um parecer de crédito?');

            if (!isConfirmed) return;

            this.callLoading(true);
            const { status } = await BalanceService.addComment(
                this.$store.state.user.data.name,
                this.data.id,
                this.description,
            );
            this.callLoading(false);

            if (status === 201) {
                this.modalSuccess('Parecer adicionado com sucesso');
            } else {
                this.modalError('Erro ao adicionar parecer');
            }
        },

    },
};
</script>
