<template>
  <div>
    <Table
      v-for="(table, index) in tables"
      :key="index"
      :items="doItems(table)"
      :fields="fields"
      class="mb-2"
    />

    <Table
      :fields="fields"
      :items="[
        { title: 'Total', value1: data[0].total, value2: data[1].total, value3: data[2].total, bold: true },
      ]"
    />
  </div>
</template>

<script>
import { DateTime } from 'luxon';

import layout from './layout';
import Table from './Table.vue';

export default {
    components: {
        Table,
    },

    props: {
        data: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            tables: [
                { title: 'floating', key: 'floating', label: 'Circulante' },
                { title: 'achievable_long_term', key: 'achievable_long_term', label: 'Realizável Longo Prazo' },
                { title: 'permanent', key: 'permanent', label: 'Permanente' },

            ],
        };
    },

    computed: {
        fields() {
            const defaultFields = [
                { key: 'title', label: 'Ativo' },
            ];

            this.dataFormatted.forEach((item, index) => {
                if (+item.total) {
                    defaultFields.push({
                        key: `value${index + 1}`,
                        label: DateTime.fromISO(item.date).plus({ days: 1 }).toFormat(
                            'dd/MM/yyyy',
                        )
                        ,
                    });
                }
            });

            return defaultFields;
        },

        dataFormatted() {
            return this.data;
        },
    },

    methods: {
        doItems(table) {
            const fullData = this.dataFormatted.map(item => item[table.key]);
            const firstItem = {
                title: table.label,
                value1: fullData[0].total,
                value2: fullData[1].total,
                value3: fullData[2].total,
                bold: true,
            };

            const items = [{ ...firstItem }];

            // eslint-disable-next-line no-restricted-syntax
            for (const index in fullData) {
                const value = fullData[index];
                firstItem[`value${+index + 1}`] = value?.total;
            }

            // eslint-disable-next-line no-restricted-syntax
            for (const iterator of layout[table.title]) {
                const item = {
                    title: iterator.label,
                };

                // eslint-disable-next-line no-restricted-syntax
                for (const index in fullData) {
                    const value = fullData[index];

                    item[`value${+index + 1}`] = value[iterator.key];
                }

                items.push(item);
            }
            const lastItem = {
                title: '',
            };

            // eslint-disable-next-line no-restricted-syntax
            for (const index in fullData) {
                const value = fullData[index];
                lastItem[`value${+index + 1}`] = value?.rest;
            }

            items.push(lastItem);
            return items;
        },
    },
};
</script>
